import React, { useState, useContext, useEffect } from 'react'
import M_MessageBoard from '../utils/M_MessageBoard/M_MessageBoard'
import M_InputTextField from '../utils/M_InputTextField/M_InputTextField'
import M_RadioButton from '../utils/M_RadioButton/M_RadioButton'
import M_Button from '../utils/M_Button/M_Button'
import './modalRegister.css'
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { IconButton } from '@mui/material'
import InputPhoneNumber from '../utils/InputPhoneNumber'
import { isValidPhoneNumber } from 'libphonenumber-js'
import ReCAPTCHA from "react-google-recaptcha"
import LoadingContext from '../utils/loading.context'
import { BufferService } from '../utils/buffer.service'
import ModalConfirmEmail from './modalConfirmEmail'

export default function ModalRegister({ setOpenModalRegister, openModalRegister }) {
    const ctx = useContext(LoadingContext)
    const backendData = new BufferService('identity/register')
    const [verified, setVerified] = useState(false)
    const apiKey = process.env.REACT_APP_SITE_KEY
    const [newPassword, setNewPassword] = useState("")

    const [openModalEmailInfo, setOpenModalEmailInfo] = useState(false)

    const reCaptchaButton = (token) => {
        setVerified(true)
    }

    const CallBackReCaptchaButton = (token) => {
        setVerified(false)
        localStorage.removeItem("_grecaptcha")
    }

    //utils to password
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword((show) => !show)
    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    //utils to check number
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const handlePhoneNumberChange = (value) => {
        setPhoneNumberValid(isValidPhoneNumber(value))
    }

    //principal user dictionary
    const [newUserAccount, setNewUserAccount] = useState(new Map())

    useEffect(() => {
        localStorage.clear()
        sessionStorage.clear()
        setPhoneNumberValid(false)
        setShowPassword(false)
        setNewPassword("")
        setNewUserAccount(new Map())
    }, [])

    const submitNewAccount = (event, index) => {
        if (index === "Phone" || (event && event.target)) {
            const value = index === "Phone" ? event : event.target.value
            newUserAccount.set(index, value)
            setNewUserAccount(new Map(newUserAccount))
        }
    }

    const handleRadioChange = (e, code) => {
        const selectedValue = e.target.value
        newUserAccount.set(code, selectedValue)
        setNewUserAccount(new Map(newUserAccount))
    }

    const inputs = [
        { label: "Nome", type: "text", code: "Name" },
        { label: "Apelido", type: "text", code: "Surname" },
        { label: "NIF", type: "number", code: "Nif" },
        { label: "Telemóvel", type: "tel", code: "Phone" },
        { label: "Email", type: "email", code: "Email" },
        { label: "Password", type: "password", code: "Password" },
        {
            label: "Sócio", type: "radio", code: "Partner", options: [
                { label: "Não", value: "nao" },
                { label: "Sim", value: "sim" }
            ]
        },
        { label: "Nº Sócio", type: "number", code: "PartnerNumber" },
        { label: "Confirmar password", type: "password2", code: "Password2" },
    ]

    const renderInput = (value) => {
        if (value.type === "text") {
            return (
                <M_InputTextField
                    required
                    label={value.label}
                    type={"text"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    onChange={(e) => submitNewAccount(e, value.code)}
                    value={newUserAccount.get(value.code) || ""}
                />
            )
        } else if (value.type === "number") {
            return (
                <M_InputTextField
                    required
                    label={value.label}
                    type={"number"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    maxNumbers={9}
                    onChange={(e) => { submitNewAccount(e, value.code) }}
                    value={newUserAccount.get(value.code) || ""}
                    disabled={value.code === "PartnerNumber" && newUserAccount.get("Partner") !== "sim"}
                />
            )
        } else if (value.type === "email") {
            return (
                <M_InputTextField
                    required
                    label={value.label}
                    type={"email"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    value={newUserAccount.get(value.code) || ""}
                    onChange={(e) => submitNewAccount(e, value.code)}
                />
            )
        } else if (value.type === "password") {
            return (
                <M_InputTextField
                    required
                    label={value.label}
                    type={"password"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    value={newUserAccount.get(value.code) || ""}
                    onChange={(e) => submitNewAccount(e, value.code)}
                    passVerification
                    minLengthverification={6}
                    valueAgain={newPassword}
                />
            )
        } else if (value.type === "radio") {
            return (
                <M_RadioButton
                    label={value.label}
                    options={value.options}
                    selectedValue={newUserAccount.get(value.code) || ""}
                    onChange={(e) => {
                        submitNewAccount(e, value.code)
                        handleRadioChange(e, value.code)
                        if (value.code === "Partner" && e.target.value === "nao") {
                            submitNewAccount({ target: { value: "" } }, "PartnerNumber")
                        }
                    }}
                    required
                />
            )
        } else if (value.type === "tel") {
            return (
                <InputPhoneNumber
                    label={value.label}
                    onChange={(e) => {
                        submitNewAccount(e, value.code)
                        handlePhoneNumberChange(e)
                    }}
                    value={newUserAccount.get(value.code) || ""}
                    error={!phoneNumberValid}
                    helperText={!phoneNumberValid ? "Número de telemóvel inválido" : "Número de telemóvel válido"}
                />
            )
        } else if (value.type === "password2") {
            return (
                <M_InputTextField
                    required
                    label={value.label}
                    type={"password"}
                    secondColor={"var(--color-black-light)"}
                    color={"var(--color-black-light)"}
                    value={newPassword || ""}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
            )
        } else {
            return null
        }
    }

    const modalBody = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <div className='modalInputFieldsRegister'>
                    <div className='left-columnRegister'>
                        <div className='singleInputsSecondPageRegister'>
                            {renderInput(inputs[0])}
                        </div>
                        <div className='singleInputsSecondPageRegister'>
                            {renderInput(inputs[1])}
                        </div>
                        <div className='singleInputsSecondPageRegister'>
                            {renderInput(inputs[3])}
                        </div>
                        <div className='singleInputsSecondPageRegister'>
                            {renderInput(inputs[2])}
                        </div>
                        <div className='singleInputsSecondPageRegister'>
                            {renderInput(inputs[6])}
                        </div>
                        <div className='singleInputsSecondPageRegister'>
                            {renderInput(inputs[7])}
                        </div>
                    </div>
                    <div className='right-columnRegister'>
                        <div className='singleInputsSecondPageRegister'>
                            {renderInput(inputs[4])}
                        </div>
                        <div className='singleInputsSecondPageRegister'>
                            {renderInput(inputs[5])}
                        </div>
                        <div className='singleInputsSecondPageRegister'>
                            {renderInput(inputs[8])}
                        </div>
                    </div>
                </div>
                <div className='ReCAPTCHADivRegister'>
                    <ReCAPTCHA
                        sitekey={apiKey}
                        onChange={reCaptchaButton}
                        onExpired={CallBackReCaptchaButton}
                        style={{ transform: 'scale(0.77)', transformOrigin: '0 0' }}
                        badge={'bottomright'}
                    />
                </div>
            </div>
        )
    }

    const footer = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ marginRight: '20px' }}>
                    <M_Button
                        height={40}
                        width={10}
                        bold
                        label={"Voltar"}
                        labelColor={'var(--color-white)'}
                        onClick={() => {
                            setOpenModalRegister(false)
                            setPhoneNumberValid(false)
                            setNewUserAccount(new Map())
                            setNewPassword("")
                        }}
                        borderRadius={15}
                        hoverEffect
                        backgroundColor={"var(--color-red)"}
                        borderColor={"var(--color-red)"}
                    />
                </div>
                <div>
                    <M_Button
                        height={40}
                        width={10}
                        bold
                        label={"Confirmar registo"}
                        labelColor={'var(--color-white)'}
                        onClick={() => { }}
                        type={"submit"}
                        borderRadius={15}
                        hoverEffect
                        backgroundColor={"var(--color-green)"}
                        borderColor={"var(--color-green)"}
                        disabled={!phoneNumberValid || !verified || (newPassword !== newUserAccount.get("Password"))}
                    />
                </div>
            </div>
        )
    }

    const backendCall = (e) => {
        ctx.setIsLoad(true)
        e.preventDefault()
        let tempValues = {
            'FirstName': newUserAccount.get("Name"),
            'LastName': newUserAccount.get("Surname"),
            'Partner': (newUserAccount.get("Partner") === 'sim') ? true : false,
            'PartnerNormalized': "",
            'PartnerNumber': (newUserAccount.get("Partner") === 'sim' ? newUserAccount.get("PartnerNumber") : ""),
            'VatNumber': newUserAccount.get("Nif"),
            'Email': newUserAccount.get("Email"),
            'UserName': newUserAccount.get("Email"),
            'PhoneNumber': newUserAccount.get("Phone"),
            'Password': newUserAccount.get("Password"),
        }
        backendData.post(tempValues).then(resPost => {
            if (resPost.name === 'AxiosError') {
                ctx.setIsLoad(false)
                let errorMessage = ''
                if (resPost.response && resPost.response.data && resPost.response.data.Errors) {
                    const errors = resPost.response.data.Errors[0]
                    if (errors.includes('User with this email already exists')) {
                        errorMessage = `O Email já se encontra registado.`
                    } else {
                        errorMessage = resPost.response.data
                    }
                } else {
                    errorMessage = resPost.response.data
                }
                ctx.toastMessages('error', errorMessage, '')
                return
            }
            ctx.setIsLoad(false)
            ctx.toastMessages("success", "", "Conta criada com sucesso!")
            setOpenModalRegister(false)
            setPhoneNumberValid(false)
            setShowPassword(false)
            setNewUserAccount(new Map())
            setOpenModalEmailInfo(true)
        })
    }

    return (
        <>
            <M_MessageBoard
                onSubmit={backendCall}
                width={"600px"}
                minWidth={"auto"}
                open={openModalRegister}
                title={"Criar nova conta"}
                body={modalBody()}
                footer={footer()}
            />
            <ModalConfirmEmail openModalEmailInfo={openModalEmailInfo} setOpenModalEmailInfo={setOpenModalEmailInfo} />
        </>
    )
}