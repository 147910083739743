import React, { useState, useContext } from 'react'
import M_InputTextField from '../utils/M_InputTextField/M_InputTextField'
import M_MessageBoard from '../utils/M_MessageBoard/M_MessageBoard'
import M_Button from '../utils/M_Button/M_Button'
import { BufferService } from '../utils/buffer.service'
import LoadingContext from '../utils/loading.context'

export default function ModalChange({ openModalPassword, setOpenModalPassword }) {
    const ctx = useContext(LoadingContext)
    const backendPut = new BufferService('identity/forgetpass')
    const [userEmail, setUserEmail] = useState("")

    const backendCall = (e) => {
        ctx.setIsLoad(true)
        e.preventDefault()

        let tempEmail = {
            'Email': userEmail,
        }

        backendPut.put(tempEmail).then(response => {
            if (response.name === 'AxiosError') {
                ctx.setIsLoad(false)
                let errorMessage = ''
                if (response.response && response.response.data && response.response.data.Errors) {
                    const errors = response.response.data.Errors[0]
                    if (errors.includes('User does not exist')) {
                        errorMessage = `O Email não se encontra registado.`
                    } else {
                        errorMessage = response.response.data
                    }
                } else {
                    errorMessage = response.response.data
                }
                ctx.toastMessages('error', errorMessage, '')
                return
            }
            ctx.setIsLoad(false)
            ctx.toastMessages("success", "", "Email enviado com sucesso com sucesso!")
            setOpenModalPassword(false)
        })
    }

    const footer = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ marginRight: '20px' }}>
                    <M_Button
                        height={40}
                        width={10}
                        bold
                        label={"Voltar"}
                        labelColor={'var(--color-white)'}
                        onClick={() => {
                            setOpenModalPassword(false)
                            setUserEmail("")
                        }}
                        borderRadius={15}
                        hoverEffect
                        backgroundColor={"var(--color-red)"}
                        borderColor={"var(--color-red)"}
                    />
                </div>
                <div>
                    <M_Button
                        height={40}
                        width={10}
                        bold
                        label={"Confirmar"}
                        labelColor={'var(--color-white)'}
                        onClick={() => { }}
                        type={"submit"}
                        borderRadius={15}
                        hoverEffect
                        backgroundColor={"var(--color-green)"}
                        borderColor={"var(--color-green)"}
                    />
                </div>
            </div>
        )
    }

    return (
        <M_MessageBoard
            // onClose={() => setOpenModalPassword(false)}
            onSubmit={backendCall}
            width={"300px"}
            minWidth={"auto"}
            open={openModalPassword}
            title={"Recuperar password"}
            body={<M_InputTextField
                required
                label={"Email"}
                type={"email"}
                secondColor={"var(--color-black-light)"}
                color={"var(--color-black-light)"}
                value={userEmail || ""}
                onChange={(e) => setUserEmail(e.target.value)}
            />}
            footer={footer()}
        />
    )
}